import React from 'react';
import Img from 'gatsby-image';
import ScrollAnimation from 'react-animate-on-scroll';
import { graphql } from 'gatsby';
import Button from '../UI/Button';
import Layout from '../components/Layout/Layout';

export default function Template({ data, ...props }) {
    if (!data) return <div>No hay publicaciones aún</div>;
    const post = data.contentfulBlogPost;
    return (
        <Layout {...props} title={post.titulo.titulo}>
            <section className="gray">
                <div className="child cfp-container cfp-container-blog cfp-blog inner">
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="blog-post" delay={500}>
                        <div
                            className="cfp-content-title"
                            dangerouslySetInnerHTML={{ __html: post.titulo.childMarkdownRemark.html }}
                        />
                        {post.portada && (
                            <div className="">
                                <Img
                                    className=""
                                    style={{ maxHeight: '500px' }}
                                    sizes={post.portada.sizes}
                                    alt="Imagen"
                                />
                            </div>
                        )}
                        <h2>{post.date}</h2>
                        <div
                            className="blog-post-content text"
                            dangerouslySetInnerHTML={{ __html: post.cuerpo.childMarkdownRemark.html }}
                        />
                        <Button initiallyVisible={true} to={`/blog`} text="Volver" />
                    </ScrollAnimation>
                </div>
            </section>
        </Layout>
    );
}

export const pageQuery = graphql`
    query($slug: String!) {
        contentfulBlogPost(slug: { eq: $slug }) {
            titulo {
                titulo
                childMarkdownRemark {
                    html
                }
            }
            slug
            cuerpo {
                childMarkdownRemark {
                    html
                }
            }
            portada {
                sizes(maxWidth: 1280) {
                    ...GatsbyContentfulSizes
                }
            }
            createdAt(formatString: "DD-MM-YYYY")
        }
    }
`;
